<template>
  <div>
    <b-card
      img-alt="card img"
      :title="applications.length?'Your Applications':''"
      class="position-static"
    >
      <!-- Stats Card Vertical -->
      <b-row
        v-if="applications.length"
        class="match-height align-items-center"
      >
        <b-col
          v-for="(mortgage,index) in applications"
          :key="index"
          xl="3"
          md="4"
          sm="6"
        >

          <application-card
            icon="GridIcon"
            :mortgage="mortgage"
            :statistic="mortgage.application.application_id"
            :statistic-title="mortgage.status"
            :date="mortgage.application.updated_at"
            color="info"
            @click.native="viewMortgage(mortgage)"
          />
        </b-col>
      <!-- <b-col
        xl="2"
        md="4"
        sm="6"
      >
        <b-overlay
          :show="newApplicationLoading"
          spinner-variant="primary"
          spinner-type="grow"
        >
          <application-card
            icon="PlusIcon"
            statistic="New Application"
            statistic-title=""
            color="success"
            :hide-subtitle="true"
            date="new application"
            @click.native="startNewApplication()"
          />
        </b-overlay>
      </b-col> -->
      </b-row>
      <b-row
        v-else
        class="match-height align-items-center justify-content-center"
      >
        <b-col
          md="4"
        >
          <strong>No Application Opened, Contact Staff.</strong>
        </b-col>
      </b-row>

      <b-modal
        id="application-modal"
        :title="`File Number: ${application_id} - Borrower: ${ mortgage.first_name } ${mortgage.last_name}`"
        hide-footer
        size="xl"
      >
        <Applications :application_id="application_id" />
      </b-modal>
    </b-card>
    <b-modal
      id="mortgage-file-modal"
      :title="`File Number: ${application_id} - Borrower: ${mortgage.first_name} ${ mortgage.last_name}`"
      size="xl"
      :centered="false"
      hide-footer
      @close="closeMortgageModal()"
    >

      <b-tabs
        v-model="tabIndex"
        content-class="mt-3"
        class="mortgage-tab"
      >
        <b-tab
          title="Overview"
          active
        >
          <div class="overview-box">
            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box">
                <feather-icon
                  class="icon-view"
                  icon="HashIcon"
                  size="25"
                />
              </div>
              <div class="content-view">
                <h5>File Number</h5>
                <span>{{ application_id }}</span>
              </div>
            </div>
            <!-- overview item end -->

            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box">
                <feather-icon
                  class="icon-view"
                  icon="AlertCircleIcon"
                  size="25"
                />
              </div>
              <div class="content-view">
                <h5>Status</h5>
                <span>{{ mortgage.status }}</span>
              </div>
            </div>
            <!-- overview item end -->

            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box">
                <feather-icon
                  class="icon-view"
                  icon="CalendarIcon"
                  size="25"
                />
              </div>
              <div class="content-view">
                <h5>Status Date</h5>
                <span>{{ mortgage.opened_date }}</span>
              </div>
            </div>
            <!-- overview item end -->

            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box">
                <feather-icon
                  class="icon-view"
                  icon="DollarSignIcon"
                  size="25"
                />
              </div>
              <div class="content-view">
                <h5>Loan Amount</h5>
                <span>{{ formartCurrency(mortgage.amount) }}</span>
              </div>
            </div>
            <!-- overview item end -->
          </div>
          <!-- overview content end -->

          <!-- overview content start -->
          <div class="overview-box">
            <!-- overview item start -->
            <div class="overview-view-item">
              <div class="icon-box">
                <feather-icon
                  class="icon-view"
                  icon="UserIcon"
                  size="25"
                />
              </div>
              <div class="content-view">
                <h5>Borrower</h5>
                <span>{{ mortgage.first_name }} {{ mortgage.last_name }}</span>
              </div>
            </div>
            <!-- overview item end -->
            <!-- overview item start -->
            <div
              v-if="mortgage.loan_originator"
              class="overview-view-item"
            >
              <div class="icon-box">
                <feather-icon
                  class="icon-view"
                  icon="UserIcon"
                  size="25"
                />
              </div>
              <div class="content-view">
                <h5>Loan Originator </h5>
                <span>{{ mortgage.loan_originator.first_name }} {{ mortgage.loan_originator.last_name }}</span>
              </div>
            </div>
            <!-- overview item end -->
            <!-- overview item start -->
            <div
              v-if="mortgage.selected_licensee"
              class="overview-view-item"
            >
              <div class="icon-box">
                <feather-icon
                  class="icon-view"
                  icon="UserIcon"
                  size="25"
                />
              </div>
              <div class="content-view">
                <h5> Real Estate Licensee </h5>
                <span>{{ licensee.first_name }} {{ licensee.last_name }}</span>
              </div>
            </div>
            <div
              v-else
              class="overview-view-item"
              style="visibility: hidden;"
            />
            <!-- overview item end -->
            <div
              class="overview-view-item"
              style="visibility: hidden;"
            />
          </div>
          <!-- overview content end -->

        </b-tab>
        <b-tab title="Mortgage Application">
          <Applications :application_id="application_id" />
        </b-tab>
        <!-- <b-tab title="Details">
          <p>No details</p>
        </b-tab> -->
        <b-tab
          title="Files"
        >
          <div class="file_upload_heading">
            <h4>Files</h4>
            <div
              class="file_upload_button"
              @click="openFileUpload"
            >
              <feather-icon
                class="icon-view"
                icon="PlusIcon"
              />
            </div>
          </div>
          <div class="file_upload_box">
            <div class="file_upload_row">
              <div class="file-col">
                <span class="heading">Preview</span>
              </div>
              <div class="file-col">
                <span class="heading">File Name</span>
              </div>
              <div class="file-col">
                <span class="heading">Uploaded By</span>
              </div>
              <div class="file-col">
                <span class="heading">Date</span>
              </div>
              <div class="file-col">
                <span class="heading">Action</span>
              </div>
            </div>

            <div
              v-for="(item, index) in mortgage.application && mortgage.application.attachments"
              :key="index"
              class="file_upload_row"
            >
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <img
                  v-if="getExtention(item.name) === 'png' || getExtention(item.name) === 'jpg' || getExtention(item.name) === 'jpeg' || getExtention(item.name) === 'svg'"
                  :src="imgTumb"
                  alt="tumb"
                  class="file_thumb"
                >

                <img
                  v-if="getExtention(item.name) === 'pdf'"
                  :src="pdfTumb"
                  alt="tumb"
                  class="file_thumb"
                >

                <img
                  v-if="getExtention(item.name) === 'doc' || getExtention(item.name) === 'docx'"
                  :src="docTumb"
                  alt="tumb"
                  class="file_thumb"
                >

                <img
                  v-if="getExtention(item.name) === 'xls' || getExtention(item.name) === 'csv'"
                  :src="xlsTumb"
                  alt="tumb"
                  class="file_thumb"
                >
              </div>
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <span class="text-primary">{{ item.name }}</span>
              </div>
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <div class="d-flex align-items-center">
                  <!-- button avatar -->
                  <b-avatar
                    button
                    variant="primary"
                    :text="`${mortgage.first_name.substr(0, 1)}${mortgage.last_name.substr(
                      0,
                      1
                    )}`"
                    class="align-baseline mr-1"
                  />
                  <span>{{ mortgage.application && mortgage.first_name }} {{ mortgage.application && mortgage.last_name }}</span>

                  <!-- link avatar -->
                  <!-- <b-avatar
      href="#foobar"
      :src="require('@/assets/images/portrait/small/avatar-s-8.jpg')"
    />
    <span>Link Avatar</span> -->
                </div>
              </div>
              <div
                class="file-col"
                :class="Boolean(item.marked_as_deleted) === true ? 'is_line_througth' : ''"
              >
                <span>{{ date(item.created_at) }}</span>
              </div>
              <div class="file-col">
                <div class="action_view">
                  <span
                    v-if="Boolean(item.marked_as_deleted) === false"
                    v-b-tooltip.hover.top="'Rename file'"
                    @click="openRenameFileModal(item)"
                  ><feather-icon
                    class="icon-view text-primary"
                    icon="Edit3Icon"
                    size="22"
                  /></span>

                  <!-- <span v-b-tooltip.hover.top="'Download file'"><feather-icon
                    class="icon-view text-info"
                    icon="DownloadCloudIcon"
                    size="22"
                  /></span> -->

                  <!-- <span v-b-tooltip.hover.top="'Delete file'"><feather-icon
                    class="icon-view text-danger"
                    icon="TrashIcon"
                    size="22"
                  /></span> -->
                </div>
              </div>
            </div>
          </div>

          <b-modal
            id="rename-file-modal"
            title="Rename File"
            hide-footer
            size=""
          >
            <span>File Name</span>
            <b-form-input
              v-model="edited_file_name"
              placeholder="file name"
            />
            <div class="d-flex d-flex justify-content-between mt-3">

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="$bvModal.hide('rename-file-modal')"
              >
                Cancel
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                @click="renameFile"
              >
                Save Changes
              </b-button>
            </div>
          </b-modal>

          <b-modal
            id="file-upload-modal"
            title="Upload File"
            hide-footer
            size=""
          >
            <vue-dropzone
              id="dropzone"
              :options="dropzoneOptions"
              :use-custom-slot="true"
              :destroy-dropzone="true"
              @vdropzone-files-added="fileUploaded"
            >
              <div class="dropzone-custom-content">
                <h3 class="dropzone-custom-title">
                  <feather-icon
                    class="icon-view"
                    icon="UploadCloudIcon"
                    size="55"
                  />
                </h3>
                <div class="subtitle">
                  Drop files here or click to upload
                </div>
              </div>
            </vue-dropzone>

            <div class="d-flex d-flex justify-content-between mt-3">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="$bvModal.hide('file-upload-modal')"
              >
                Cancel
              </b-button>

              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-primary"
                :loading="uploading"
                loading-text="file uploading..."
                @click="uploadFiles"
              >
                Upload File
              </b-button>
            </div>
          </b-modal>
        </b-tab>
        <b-tab>
          <template #title>
            Discussion<div
              v-if="mortgage.unread_messages !== 0"
              class="main_notify_box"
              style="margin-left:10px !important"
            >
              <feather-icon
                icon="BellIcon"
                size="22"
              />
              <div class="count_box">
                {{ mortgage.unread_messages }}
              </div>
            </div>
          </template>
          <Chat
            v-if="showChats"
            :mortgage_id="mortgage.id"
          />
        </b-tab>
        <b-tab
          title="Notes"
          @click="loadNote"
        >
          <Notes
            v-if="is_note_enable === true"
            :application="mortgage"
            :mortgage_id="mortgage.id"
          />
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import {
  BTable, VBTooltip, BRow, BCol, BCard, BBadge, BModal, BButton, BAvatar,
  BTabs,
  BTab,
  BFormInput,
} from 'bootstrap-vue'
import HwaButton from '@/layouts/components/HwaButton.vue'
import showToast from '@/mixins/showToast'
import Applications from '@/views/app/client/Applications.vue'
import confirm from '@/mixins/confirm'
import TestGenerate from '@/views/app/staff/SectionOne'
import HwaModal from '@/layouts/components/HwaModal.vue'
import Chat from '@/views/app/chat/Chat.vue'
import vue2Dropzone from 'vue2-dropzone'
import Ripple from 'vue-ripple-directive'
import ApplicationCard from './ApplicationCard.vue'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import imgTumb from '../../../../assets/images/files/image.png'
import pdfTumb from '../../../../assets/images/files/pdf.png'
import xlsTumb from '../../../../assets/images/files/xls.png'
import docTumb from '../../../../assets/images/files/doc.png'
import Notes from '../../components/Notes.vue'
import realestateLicensee from '@/mixins/realestateLicensee'
import moment from 'moment'
import bus from '../../../../libs/bus'

export default {
  name: 'Index',
  components: {
    ApplicationCard,
    BCard,
    BRow,
    BCol,
    BModal,
    Applications,
    BBadge,
    TestGenerate,
    HwaButton,
    HwaModal,
    BTable,
    Chat,
    BTabs,
    BTab,
    BButton,
    vueDropzone: vue2Dropzone,
    BAvatar,
    BFormInput,
    Notes,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  mixins: [showToast, confirm, realestateLicensee],
  data() {
    return {
      imgTumb,
      pdfTumb,
      xlsTumb,
      docTumb,
      dropzoneOptions: {
        url: 'https://httpbin.org/post',
        thumbnailWidth: 150,
        maxFilesize: 0.5,
        headers: { 'My-Awesome-Header': 'header value' },
        addRemoveLinks: true,
      },
      newApplicationLoading: false,
      application_id: null,
      showDetails: false,
      mortgage: {},
      applications: [],
      uploaded_attachments: [],
      uploading: false,
      edited_file_name: '',
      file_extention: '',
      uploaded_file_details: {},
      tabIndex: 0,
      is_note_enable: false,
    }
  },
  computed: {
    showChats() {
      return this.tabIndex === 3
    },
    check_application_change() {
      return this.$store.state.application.enable_application_view
    },
    is_application_change() {
      return this.$store.state.application.enable_application
    },
    mortgages() {
      return this.$store.getters['chat/mortgages']
    },
    licensee() {
      return this.licensees.find(b => (+b.id === +this.mortgage.selected_licensee))
    },
  },
  watch: {
    mortgages(val) {
      this.applications = val
    },
    tabIndex(val) {
      if (val === 3) {
        this.mortgage.unread_messages = 0
      }
    },
  },
  destroyed() {
    bus.$off('new-message')
  },
  mounted() {
    bus.$on('new-message', id => {
      this.applications = this.applications.map(m => {
        if (+m.id === +id) {
          m.unread_messages++
        }
        return m
      })
    })
    this.getApplications()
    this.getRealestateLicensees()
  },
  methods: {
    getExtention(name) {
      const extention = name.split('.')
      return extention[1]
    },
    loadNote() {
      this.is_note_enable = true
    },
    date(data) {
      return moment(data).format('ddd DD-MMM-YY')
    },
    openFileUpload() {
      this.$bvModal.show('file-upload-modal')
    },
    fileUploaded(files) {
      this.uploaded_attachments = Object.values(files)
    },
    uploadFiles() {
      const attachments = new FormData()
      attachments.append('application_id', this.mortgage.id)
      this.uploaded_attachments.forEach(file => {
        attachments.append('attachments[]', file)
      })
      this.uploading = true
      this.$http.post('api/mortgage/attachments', attachments).then(res => {
        this.$bvModal.hide('file-upload-modal')
        this.mortgage.application.attachments = res.data
        this.uploading = false
        this.showSuccess('Uploaded attachment successfully')
      }).catch(err => {
        this.uploading = false
        this.showError('Failed to upload files')
      })
    },
    formartCurrency(amount) {
      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',
      })

      const str = formatter.format(amount)
      const splitStr = str.split('.')
      return splitStr[0]
    },
    closeMortgageModal() {
      this.showDetails = false
    },
    viewMortgage(mortgage) {
      this.mortgage = { ...mortgage }
      this.$store.commit('auth/setLoanOriginator', mortgage.loan_originator)
      this.showDetails = true
      this.application_id = mortgage.application.application_id
      this.$bvModal.show('mortgage-file-modal')
    },
    openChats() {
      this.$bvModal.show('open-chat')
    },
    continueApplication() {
      const application = { ...this.mortgage.application }
      this.$store.commit('auth/setLoanApplication', application)
      this.$store.commit('auth/setMortgageId', this.mortgage.id)
      this.$store.commit('auth/setMortgageStatus', this.mortgage.status)
      this.$bvModal.show('application-modal')
      this.application_id = application.application_id
      // this.$router.push({ path: `customer-applications/${application.application_id}` })
    },
    // startNewApplication() {
    //   this.newApplicationLoading = true
    //   this.$http.post('/api/applications/start')
    //     .then(res => {
    //       this.newApplicationLoading = false
    //       this.$store.commit('auth/setLoanApplication', res.data)
    //       this.$store.commit('auth/setLoanApplicationsCount', 1)
    //       this.$router.push({ path: `customer-applications/${res.data.application_id}` })
    //     })
    //     .catch(err => {
    //       this.newApplicationLoading = false
    //       this.showError('Can not start a new application')
    //     })
    // },
    getApplications() {
      this.$http.get('/api/mortgages')
        .then(res => {
          // this.applications = res.data
          this.$store.commit('chat/setMortgages', res.data)
          this.$store.commit('auth/setLoanApplications', res.data)
        })
        .catch(err => {
          this.showError('Can not load applications')
        })
    },
    openRenameFileModal(item) {
      const getFileNameWithoutExtention = item.name.split('.')
      this.file_extention = this.getExtention(item.name)
      // eslint-disable-next-line prefer-destructuring
      this.edited_file_name = getFileNameWithoutExtention[0]
      this.uploaded_file_details = item
      this.$bvModal.show('rename-file-modal')
    },
    renameFile() {
      const data = this.uploaded_file_details
      data.name = `${this.edited_file_name}.${this.file_extention}`
      this.$http.put(`api/mortgage/update-attachment-details/${data.id}`, data).then(res => {
        this.mortgage.application.attachments = res.data
        this.$bvModal.hide('rename-file-modal')
        this.file_extention = ''
        this.edited_file_name = ''
        this.uploaded_file_details = {}
        this.showSuccess('file rename successfully')
      }).catch(err => {
        this.uploading = false
        this.showError('Failed to rename file')
      })
    },
  },
}
</script>
