<template>
  <b-card
    class="text-center"
    style="border: 1px solid gray; cursor: pointer"
  >
    <div class="notification_view_box float-right">
      <div
        v-if="mortgage && mortgage.unread_messages !== 0"
        class="main_notify_box"
      >
        <feather-icon
          icon="BellIcon"
          size="22"
        />
        <div class="count_box">
          {{ mortgage && mortgage.unread_messages }}
        </div>

      </div>
    </div>
    <b-avatar
      class="mb-1"
      :variant="`light-${color}`"
      size="45"
    >
      <feather-icon
        size="21"
        :icon="icon"
      />
    </b-avatar>
    <div class="truncate">
      <h6 class="mb-25 font-weight-bolder">
        {{ statistic }}
      </h6>

      <div v-if="!hideSubtitle">
        <div
          class="text-center"
        >
          <b-badge variant="light-success">
            {{ statisticTitle }}
          </b-badge>
        </div>
        <small><b>Updated</b> {{ date }}</small>
      </div>

    </div>
  </b-card>
</template>

<script>
import { BCard, BAvatar, BBadge } from 'bootstrap-vue'

export default {
  name: 'ApplicationCard',
  components: {
    BCard,
    BAvatar,
    BBadge,
  },
  props: {
    date: {
      type: String,
      required: true,
    },
    hideSubtitle: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      required: true,
    },
    statistic: {
      type: [Number, String],
      required: true,
    },
    statisticTitle: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    mortgage: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
